import React from "react";
import { connect } from "react-redux";
import LeadForm from "../components/LeadForm";
import { Link } from "react-router-dom";
import CtaButton from "../components/CtaButton";
import StudentTestimonials from "../components/StudentTestimonials";

const Home = () => {
    return (
        <>
            {/* <div id="loading-progress">
                <a className="logo" href="#">
                    <img src="images/files/logo-loading.png" data-logo-alt="images/files/logo-header.png" alt="" />
                    <h3><span className="colored">Cloin</span></h3>
                    <span>HTML Template</span>
                </a>
                <div className="lp-content">
                    <div className="lp-counter">
                        Loading
                        <div id="lp-counter">0%</div>
                    </div>
                    <div className="lp-bar">
                        <div id="lp-bar"></div>
                    </div>
                </div>
            </div> */}

            <div id="full-container">

                <header id="header">

                    <div id="header-bar-1" className="header-bar">
                
                        <div className="header-bar-wrap">
                
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                
                                        <div className="hb-content">
                                            <a className="logo logo-header" href="https://www.sablestudyabroad.com/" target="_blank" style={{height: "80px"}}>
                                                <img src={require('../assets/images/logo.webp')} data-logo-alt={require('../assets/images/logo.webp')} alt="Sable Study Abroad" />
                                                <h3><span className="colored">Sable Study Abroad</span></h3>
                                                <span>Ivy League Admission Counselling</span>
                                            </a>
                                        </div>
                
                                    </div>
                                </div>
                            </div>

                        </div>
                
                    </div>

                </header>

                <section id="banner">
                
                    <div className="banner-parallax" data-banner-height="650" style={{background: `url("${require("../assets/images/files/parallax-bg/Princeton-58b46bf73df78cdcd81ca8db.jpg")}") no-repeat fixed center / cover`}}>
                        <div className="overlay-colored opacity-85" style={{backgroundColor: "#fff"}}></div>
                        <div className="slide-content" >
                            <div className="container" >
                                <div className="row">
                                    <div className="col-md-6">
                                    
                                        <div className="banner-center-box">
                                            <span className="subheading" style={{color: "#222"}}>REALISE YOUR DREAMS</span>
                                            <h1>
                                                <span className="heading-200">HERE IS YOUR CHANCE TO GET INTO THE </span>
                                                BEST UNIVERSITIES OF THE WORLD
                                            </h1>
                                            <p className="description" style={{color: "#222"}}>
                                                Our Ivy League counsellors are here to help you make your dream college become a reality.
                                            </p>
                                            {/* <div className="btn-row mt-20">
                                                <CtaButton />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-md-push-1">
                                    
                                        <div className="banner-center-box">
                                            <div className="cta-subscribe cta-subscribe-1 box-form text-center">
                                                <div className="box-title">
                                                    <span className="icon icon-Megaphone"></span>
                                                    <h3>Schedule a Free Consultation</h3>
                                                    <p>Kindly complete the form with your details, and our consultant will promptly reach out to you to address your requirements!</p>
                                                </div>
                                                <div className="box-content">
                                                    <div className="cs-notifications">
                                                        <div className="cs-notifications-content"></div>
                                                    </div>
                                                   <LeadForm />
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </section>
                
                <div id="section-our-services-1" className="section-flat">
                
                    <div className="section-content">
                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-push-2">
                                    
                                    <h2 className="title-featured-1 text-center">
                                        <span className="heading-200">Welcome to Sable Study Abroad. It’s time to realise your dreams and take charge of your education and life. We provide Admission Consultancy for </span>
                                        <span style={{color: "#863260"}}>IVY LEAGUES and the TOP UNIVERSITIES and COLLEGES in USA, UK,CANADA and EUROPE.</span>
                                        <span className="heading-200"> We have a team of highly </span> 
                                        experienced Ivy League-educated counsellors
                                        <span className="heading-200"> who will guide you through the complete admission process. We are committed to helping you </span>
                                        find the BEST COURSE and COLLEGE/UNIVERSITY.
                                        <span className="heading-200"> We also provide test preparation for </span>
                                        IELTS, SAT, GRE or GMAT.<br />
                                        ADVANTAGE - <span className="heading-200">all our services are available  to you </span>
                                        ONLINE 
                                        <span className="heading-200"> at very </span>REASONABLE PRICES.
                                        <span className="heading-200"> So,what are you waiting for? Connect with us now by filling up the form and fulfil your dreams.</span>
                                    </h2>
                                    <div className="text-center mt-20"><CtaButton /></div>
                                </div>
                                <div className="col-md-12">
                                
                                    <div className="divider-container">
                                        <div className="divider-line">
                                
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                
                                    <div className="box-info box-service-1 mb-md-40">
                                        <div className="box-icon">
                                            <span className="icon icon-Tie"></span>
                                        </div>
                                        <div className="box-content">
                                            <h4><a href="javascript:;">Ivy League Expertise</a></h4>
                                            <p>
                                                Sable specializes in guiding students through the rigorous admissions process of Ivy League institutions. With a team of consultants who are Ivy League graduates themselves, we offer unparalleled expertise and insider knowledge to help students navigate the complexities of these highly competitive schools.
                                            </p>
                                        </div>
                                    </div>
                
                                </div>
                                <div className="col-md-3 col-sm-6">
                
                                    <div className="box-info box-service-1 mb-md-40">
                                        <div className="box-icon">
                                            <span className="icon icon-Users"></span>
                                        </div>
                                        <div className="box-content">
                                            <h4><a href="javascript:;">Personalized Approach</a></h4>
                                            <p>
                                                Our consultants work closely with each student, taking the time to understand their unique strengths, interests, and goals. Through one-on-one consultations, we develop customized strategies that highlight the individuality of each student, enabling them to create standout applications that resonate with Ivy League admissions committees.
                                            </p>
                                        </div>
                                    </div>
                
                                </div>
                                <div className="col-md-3 col-sm-6">
                
                                    <div className="box-info box-service-1 mb-sm-40">
                                        <div className="box-icon">
                                            <span className="icon icon-Resume"></span>
                                        </div>
                                        <div className="box-content">
                                            <h4><a href="javascript:;">Comprehensive Support</a></h4>
                                            <p>
                                                Sable provides comprehensive support throughout the entire college admission journey. From creating a balanced list of target schools to crafting compelling essays, preparing for interviews, and guiding students in showcasing their extracurricular activities, we ensure that all aspects of the application are addressed. Our goal is to equip students with the tools and knowledge needed to present their strongest application to Ivy League colleges.
                                            </p>
                                        </div>
                                    </div>
                
                                </div>
                                <div className="col-md-3 col-sm-6">
                
                                    <div className="box-info box-service-1">
                                        <div className="box-icon">
                                            <span className="icon icon-Medal"></span>
                                        </div>
                                        <div className="box-content">
                                            <h4><a href="javascript:;">Success Track Record</a></h4>
                                            <p>
                                            We take pride in our track record of success. Our company has helped numerous students gain admission to Ivy League institutions, with a high acceptance rate and success stories that speak for themselves. Our proven strategies, combined with our consultants' expertise, have consistently yielded outstanding results for our clients, making us a trusted choice for those aiming to attend an Ivy League college.
                                            </p>
                                        </div>
                                    </div>
                
                                </div>
                
                            </div>
                        </div>
                
                    </div>
                
                </div>

                
                <div id="section-our-services-2" className="section-flat">
                
                    <div className="section-content">
                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                
                                    <div className="section-title mt-10 mt-md-0">
                                            <h2>
                                                <span className="heading-200">Looking for </span> Expert Advice and Guidance <span className="heading-200">for your Study Abroad plans</span>
                                            </h2>
                                            {/* <p className="description">
                                                Looking for Expert Advice and Guidance for your Study Abroad plans, you’ve come to the right place
                                            </p> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            
                                            <div className="box-info box-service-2">
                                                <div className="box-icon">
                                                    <span className="icon icon-Crown"></span>
                                                </div>
                                                <div className="box-content">
                                                    <h4>Admissions</h4>
                                                    <p>
                                                        Bachelor's, Master's, PhD
                                                    </p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <div className="col-sm-6">

                                            <div className="box-info box-service-2 mb-20">
                                                <div className="box-icon">
                                                    <span className="icon icon-Notebook"></span>
                                                </div>
                                                <div className="box-content">
                                                    <h4>Test Preparation</h4>
                                                    <p>
                                                        IELTS, SAT, ACT, GRE, GMAT
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-6">
                                        
                                            <div className="box-info box-service-2 mb-20">
                                                <div className="box-icon">
                                                    <span className="icon icon-Resume"></span>
                                                </div>
                                                <div className="box-content">
                                                    <h4>Career Counselling</h4>
                                                    <p>
                                                        SABLE ACADEMIC COACHING
                                                    </p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="mt-40"><CtaButton /></div>

                                </div>
                                <div className="col-md-6 mt-md-60">
                                    <div className="video-preview">
                                        <iframe 
                                            width="100%"
                                            style={{maxWidth: "100%", minHeight: "425px"}}
                                            src="https://www.youtube.com/embed/jmRvfZJyEJE" 
                                            title="Sable Study Abroad | Here Is Your Chance To Get Into The Best Universities Of The World" 
                                            frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                            allowfullscreen></iframe>
                                    </div>

                                    {/* <div className="video-preview">
                                        <a className="img-bg lightbox-iframe" href="https://www.facebook.com/sablestudyabroad/videos/1273857439884069" target="_blank">
                                            <div className="overlay-colored color-bg-dark opacity-50"></div>
                                            <img src={require("../assets/images/files/img-featured-1/img-1.jpg")} alt="" />
                                        </a>
                                        <a className="btn-video lightbox-iframe" href="https://www.facebook.com/sablestudyabroad/videos/1273857439884069" target="_blank">
                                            <i className="fa fa-play"></i>
                                        </a>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                
                    </div>
                
                </div>

                
                <div id="section-some-facts-1" className="section-parallax" style={{background: `url("${require("../assets/images/files/parallax-bg/img-2.jpg")}") no-repeat fixed center / cover`}}>
                    <div className="overlay-colored color-bg-dark opacity-85"></div>
                    <div className="section-content">
                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                
                                    <ul className="list-some-facts list-boxes boxes-4">
                                        <li>
                                            <div className="box-info box-some-fact-1 text-white">
                                                {/* <div className="box-icon">
                                                    <span className="icon icon-Files"></span>
                                                </div> */}
                                                <div className="box-content">
                                                    <h2><span className="counter-stats">100+</span></h2>
                                                    <h5>Yearly Admissions</h5>
                                                </div>
                                            </div>
                                
                                        </li>
                                        <li>
                                            <div className="box-info box-some-fact-1 text-white">
                                                {/* <div className="box-icon">
                                                    <span className="icon icon-Users"></span>
                                                </div> */}
                                                <div className="box-content">
                                                    <h2><span className="counter-stats">1000+</span></h2>
                                                    <h5>Students</h5>
                                                </div>
                                            </div>
                                
                                        </li>
                                        <li>
                                            <div className="box-info box-some-fact-1 text-white">
                                                {/* <div className="box-icon">
                                                    <span className="icon icon-MessageLeft"></span>
                                                </div> */}
                                                <div className="box-content">
                                                    <h2><span className="counter-stats">300+</span></h2>
                                                    <h5>Degrees in Offer</h5>
                                                </div>
                                            </div>
                                
                                        </li>
                                        <li>
                                            <div className="box-info box-some-fact-1 text-white">
                                                {/* <div className="box-icon">
                                                    <span className="icon icon-Time"></span>
                                                </div> */}
                                                <div className="box-content">
                                                    <h2><span className="counter-stats">100+</span></h2>
                                                    <h5>Partners</h5>
                                                </div>
                                            </div>
                                
                                        </li>
                                    </ul>
                                
                                </div>
                            </div>
                        </div>
                
                    </div>
                
                </div>

                
                
                <StudentTestimonials />
                
                

                
                {/* <div id="section-clients-1" className="section-flat">
                
                    <div className="section-content">
                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                
                                    <div className="slider-clients">
                                        <ul className="slick-slider">
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-1.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-2.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-3.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-4.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-5.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-6.png" alt="" /></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="client-single">
                                                    <a href="javascript:;"><img src="images/files/sliders/clients/img-2.png" alt="" /></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                
                                </div>
                            </div>
                        </div>
                
                    </div>
                
                </div> */}

                <div id="section-cta-1" className="section-parallax center-vertical text-white" style={{background: `url("${require("../assets/images/files/parallax-bg/img-3.jpg")}") no-repeat fixed center / cover`}}>
                
                    {/* <img src="images/files/parallax-bg/img-3.jpg" alt="" /> */}
                    <div className="overlay-colored color-bg-dark opacity-85"></div>
                    <div className="section-content">
                
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="box-center">
                                        <h1>
                                            {/* <span className="heading-200">We Are Waiting</span> */}
                                            <br />
                                            Admission Counselling | Career Counselling | IELTS | ACT | SAT | GRE | GMAT
                                        </h1>
                                        <p className="description">
                                            If you aspire to study in best universities of the world, we have the solutions
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box-center">
                                        <div className="btn-row style-2 mt-md-50">
                                            <CtaButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>

                </div>
                <footer id="footer">
		
                    <div id="footer-bar-1" class="footer-bar">
                
                        <div class="footer-bar-wrap">
                
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                
                                        <div class="fb-row">
                                            <div class="copyrights-message">
                                                <span>Copyright © {new Date().getFullYear()}</span> <a href="https://www.sablestudyabroad.com/" target="_blank">Sable EdTech Pvt Ltd.</a> <span>All Rights Reserved.</span>
                                            </div>
                                            <ul class="social-icons x4 grey-bg hover-colorful-bg animated rounded">
                                                <li><a class="si-linkedin" href="https://www.linkedin.com/company/sablestudyabroad/" target="_blank"><i class="fa fa-linkedin"></i><i class="fa fa-linkedin"></i></a></li>
                                                <li><a class="si-facebook" href="https://www.facebook.com/sablestudyabroad" target="_blank"><i class="fa fa-facebook"></i><i class="fa fa-facebook"></i></a></li>
                                                <li><a class="si-instagram" href="https://www.instagram.com/sable_study_abroad" target="_blank"><i class="fa fa-instagram"></i><i class="fa fa-instagram"></i></a></li>
                                            </ul>
                
                                        </div>
                
                                    </div>
                                </div>
                            </div>
                
                        </div>
                
                    </div>
                
                </footer>

            </div>
        </>
    )
}

export default connect(null, {})(Home);