import React from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import testimonials from "../data/testimonials.json";

const StudentTestimonials = ({}) => {
    return (
        <div id="section-customers-testimonials-1" className="section-flat">
                
            <div className="section-content">
        
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-md-push-3">
        
                            <div className="section-title text-center">
                                <h2>
                                    {/* <span className="heading-200">What Are Our</span> */}
                                    {/* <br /> */}
                                    Student Testimonials
                                </h2>
                                {/* <p className="description">
                                    This should be used to tell a story and let your users know a little more about your product
                                    or service. How
                                    can you benefit them?
                                </p> */}
                            </div>
        
                        </div>
                        <div className="col-md-12">
                                <Swiper
                                    className="slider-testimonials mt-10"
                                    spaceBetween={50}
                                    modules={[Autoplay]}
                                    loop={true}
                                    speed={1000}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false
                                    }}
                                    slidesPerView={1}
                                    breakpoints={{
                                        480: {
                                            slidesPerView: 1
                                        },
                                        768: {
                                            slidesPerView: 1
                                        },
                                        992: {
                                            slidesPerView: 2
                                        },
                                        1200: {
                                            slidesPerView: 2
                                        },
                                        1400: {
                                            slidesPerView: 2
                                        },
                                    }}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}>
                                    {testimonials.map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="slide">
                                                    <div className="testimonial-single-1">
                                                        <div className="ts-content">
                                                            {item.message}
                                                            <span className="icon-quote">“</span>
                                                        </div>
                                                        <div className="ts-person">
                                                            <div className="ts-img">
                                                                <img src={item.src} alt={item.name} />
                                                            </div>
                                                            <div className="ts-name">
                                                                <h4>{item.name}</h4>
                                                                <span>{item.admission}</span>
                                                            </div>
                                                            <div className="rating">
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                                <i className="fa fa-star"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
        
            </div>
        
        </div>
    )
}

export default connect(null, {})(StudentTestimonials);