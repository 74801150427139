import React, { useState } from "react";
import { connect } from "react-redux";
import axios from 'axios';

const LeadForm = ({}) => {
    const [Form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        product: "Bachelor's in Top 100 Universities"
    })
    const [Submitting, setSubmitting] = useState(false);
    const handleChange = (e) => {
        setForm({...Form, [e.target.name]: e.target.value})
    }
    const validateForm = () => {
        let response = {
            success: false,
            message: 'Validation not started'
        };
        if (!Form.name || Form.name.length <= 0){
            response.message = "Please enter your name";
            return response;
        }
        if (!Form.email || Form.email.length <= 0){
            response.message = "Please enter your Email ID";
            return response;
        }
        if (!Form.phone || Form.phone.length <= 0){
            response.message = "Please enter your phone number";
            return response;
        }
        if (!Form.product || Form.product.length <= 0){
            response.message = "Please select course you are looking for";
            return response;
        }
        response.success = true;
        return response;

    }
    const submit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSubmitting(true);
        let validation = validateForm();
        if (!validation.success){
            window.alert(validation.message);
            setSubmitting(false);
            return;
        }
        
        const newRecord = {
            name: Form.name,
            fields: {
                email: Form.email,
                phone: Form.phone,
                product: Form.product
            },
            status: 'publish',
            // recaptchaToken: recaptchaToken
        };

        try {
            const response = await axios.post(
              'https://www.sablestudyabroad.com/wp-json/wp/v2/enquiry',
              newRecord,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
            // window.alert("We have received your request. Our team will get back to you shortly");
            window.location.href = 'https://www.sablestudyabroad.com/success-response/';
            // setForm({
            //     name: '',
            //     email: '',
            //     phone: '',
            //     product: "Bachelor's in Top 100 Universities"
            // })
          } 
        catch (error) {
            window.alert("Something went wrong. Could you try again?");
        }
        finally{
            setSubmitting(false);
        }
    }
    return (
        <>
            <form id="form-cta-subscribe-1" onSubmit={submit} class="redirected">
                <div class="form-group">
                    <span class="field-icon icon icon-User"></span>
                    <input type="text" name="name" onChange={handleChange} value={Form.name} id="cs1Name" class="form-control" placeholder="Name" />
                </div>
                <div class="form-group">
                    <span class="field-icon icon icon-Mail"></span>
                    <input type="text" name="email" onChange={handleChange} value={Form.email} id="cs1Email" class="form-control" placeholder="Email" />
                </div>
                <div class="form-group">
                    <span class="field-icon icon icon-Phone2"></span>
                    <input type="text" name="phone" onChange={handleChange} value={Form.phone} id="cs1PhoneNum" class="form-control" placeholder="Phone Number" />
                </div>
                <div className="form-group">
                    {/* Admission Counselling, IELTS, SAT, GRE, GMAT, Career Counselling */}
                    {/* <span class="field-icon icon icon-Phone2"></span> */}
                    <select name="product" onChange={handleChange} value={Form.product} id="cs1Product" class="form-control" placeholder="Select Product">
                        <option value="Bachelor's in Ivy League">Bachelor's in Top 100 Universities</option>
                        <option value="Masters in Ivy League">Masters in Top 100 Universities</option>
                        <option value="Test Preparation: SAT">Test Preparation: SAT</option>
                        <option value="Test Preparation: IELTS">Test Preparation: IELTS</option>
                        <option value="Test Preparation: ACT">Test Preparation: ACT</option>
                        <option value="Test Preparation: GMAT">Test Preparation: GMAT</option>
                        <option value="Test Preparation: GRE">Test Preparation: GRE</option>
                        <option value="Career Counselling for studying abroad">Career Counselling for studying abroad</option>
                    </select>
                </div>
                <div class="form-group">
                    <input type="submit" class="form-control" value={Submitting ? "Please Wait..." : "Submit"} disabled={Submitting} />
                </div>
            </form>
        </>
    )
}

export default connect(null, {})(LeadForm);