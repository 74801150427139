import React from "react";
import { connect } from "react-redux";
import 'swiper/css';
import 'swiper/css/bundle';
import '../assets/css/css-assets.css';
import '../assets/css/style.css';

const Layout = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

export default connect(null, {})(Layout);