import React from "react";
import { Link } from "react-router-dom";

const CtaButton = () => {
    const focusForm = (e) => {
        e.preventDefault();
        document.getElementById("cs1Name").focus();
    }
    return (
        <>
            <Link onClick={focusForm} className="btn x-large colorful hover-dark rounded move-top" to="#">Schedule a Free Consultation</Link>
        </>
    )
}

export default CtaButton;